<template>
  <div class="grid">
    <div class="col-12 md:col-6">
      <form @submit.prevent="handleSubmit">
        <div class="card p-fluid">
          <h5>Register</h5>
          <transition-group name="p-message" tag="div">
            <Message
              v-for="msg of message"
              :key="msg.id"
              :closable="false"
              :sticky="false"
              :life="msg.life"
              :severity="msg.severity"
              >{{ msg.content }}</Message
            >
          </transition-group>

          <div class="field grid">
            <label for="name" class="col-12 mb-2 md:col-2 md:mb-0">Name</label>
            <div class="col-12 md:col-10">
              <InputText id="name" v-model="form.name" type="text" required />
            </div>
          </div>
          <div class="field grid">
            <label for="email" class="col-12 mb-2 md:col-2 md:mb-0"
              >E-mail</label
            >
            <div class="col-12 md:col-10">
              <InputText
                id="email"
                v-model="form.email"
                type="email"
                required
              />
            </div>
          </div>
          <div class="field grid">
            <label for="password" class="col-12 mb-2 md:col-2 md:mb-0"
              >Password</label
            >
            <div class="col-12 md:col-10">
              <InputText
                id="password"
                v-model="form.password"
                type="password"
                required
              />
            </div>
          </div>
          <div class="field grid">
            <label for="confirmPassword" class="col-12 mb-2 md:col-2 md:mb-0"
              >Confirm password</label
            >
            <div class="col-12 md:col-10">
              <InputText
                id="confirmPassword"
                v-model="form.confirmPassword"
                type="password"
                required
              />
            </div>
          </div>
          <Button label="Register" type="submit"></Button>
          <p class="mt-2">
            <small>
              <router-link
                class="text-sm mt-6 text-center"
                :to="{ name: 'Home' }"
                >Already have an account? Login</router-link
              ></small
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import useAuthUser from '@/use/useAuthUser'

const { register } = useAuthUser()

const form = ref({
  name: '',
  email: '',
  password: '',
  confirmPassword: ''
})
const message = ref([])
let count = 1

const handleSubmit = async () => {
  if (form.value.password === form.value.confirmPassword) {
    try {
      form.value.confirmPassword = null // unset this so it isn't stored in the raw_user_meta_data column in supabase
      await register(form.value)
      addMessage(
        'info',
        'Please confirm your e-mail to complete registration: ' +
          form.value.email,
        6000
      )
      return
    } catch (error) {
      addMessage('error', error.message)
      return
    }
  }
  addMessage('error', 'Passwords do not match')
}

const addMessage = (type, content, life = 3000) => {
  message.value = [
    {
      severity: type,
      content: content,
      id: count++,
      life: life
    }
  ]
}
</script>
